import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { get3dAvtarModel } from "../../actions/UserAction";
import { FirebaseContext } from "../../providers/FirebaseProviders";
import ThreeDModel from "../home/ThreeDModel";

// enum genders {
//   'male' = "Male",
//   'female' = "Female"
// }

const DateAndLastMeasurement = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 14px;
  font-weight: normal;
  margin-left: 3%;
  margin-right: 3%;
  .measurement-label {
    color: ${({ theme }) => theme.colors.color};
    font-weight: 500;
    font-size: 18px;
  }
`;

const MeasurementSize = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: normal;
  margin-left: 3%;
  margin-right: 3%;
  .measurement {
    color: ${({ theme }) => theme.colors.color}
  }
`;

const MeasurementList = styled.div`
  display: flex;
  flex-direction: row;
  margin: 3px 0px;
  justify-content: space-between;
`;

const Data = styled.div`
  font-weight: bold;
  display: flex;
  color: ${({ theme }) => theme.colors.color};
`;

const Hr = styled.hr`
  margin-left: 3%;
  margin-right: 3%;
  margin-bottom: 15px;
`;

const MeasurementId = styled.div`
  margin: 1em 0px;
  display: flex;
  flex-direction: column;
  font-size: 20px;
  font-weight: normal;
  color: ${({ theme }) => theme.colors.color};
  text-align: center;
  span {
    font-size: 28px;
    font-weight: bold;
    @media (max-width: 600px) {
      font-size: 20px;
      padding: 10px 0px;
    }
  }
`;

const CopiedMeasurementId = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.color};
  width: 100%;
  margin-bottom: 30px;
`;

const DottedLine = styled.hr`
border-top:1px dotted white; 
width: 100%;
`;

const SubContainer = styled.div`
  display: flex;
  justify-content: space-between;
  min-height: 350px;
  margin-top: 30px;
  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
  }
`;

const MeasurementContainer = styled.div`
  width: 40%;
  @media (max-width: 600px) {
    width: 90%;
  }
`;

const NotValidPath = styled.div`
  display: flex;
  width: 50%;
  align-items: center;
  text-align: center;

  @media (max-width: 600px) {
    width: 100%;
    margin-top: 100px;
  }
`

const uiMeasurements = [
  { label: "shoulderWidth", value: (m: any) => m.width?.shoulder },
  { label: "chestCircumference", value: (m: any) => m.circumference?.chest },
  { label: "armLength", value: (m: any) => m.length?.arm },
  { label: "maxWaistCircumference", value: (m: any) => m.circumference?.waist },
  { label: "pelvisHipCircumference", value: (m: any) => m.circumference?.hip },
  { label: "legLength", value: (m: any) => m.length?.leg },
  { label: "insideLeg", value: (m: any) => m.length?.insideLeg },
];

const MeasurementResults = (props: any) => {
  const [copied, setCopied] = useState(false);
  const { t } = useTranslation();
  const { measurement } = props;
  const dispatch = useDispatch();
  // const poses = useSelector((state: any) => state.userInfo.poses);
  const authContext = useContext(FirebaseContext);
  // const avatarPose = poses ? JSON.parse(poses) : [];
  // const setDefaultAvatarPose = avatarPose.find((el: any) => el.id === "default");
  let date = measurement ? new Date(measurement.timestamp) : new Date();
  date = date || new Date();

  const copyToClipboard = (text: any) => {
    const textField = document.createElement("textarea");
    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    setCopied(true);
  };

  useEffect(() => {
    (async () => {
      const token: any = await authContext?.user?.getIdToken(true);
      dispatch(get3dAvtarModel(token, measurement.measurementId));
    })()
  }, [measurement, authContext, dispatch])
  return (
    <div>
      <SubContainer>
        <MeasurementContainer>
          <DateAndLastMeasurement>
            <div className="measurement-label">{t("lastMeasurement")}</div>
            <div className="measurement-label">{date.toLocaleString()}</div>
          </DateAndLastMeasurement>
          <Hr />
          <MeasurementSize>
            {uiMeasurements.length > 0 && uiMeasurements.map((meas: any, i: number) => (
              <div>
                <MeasurementList key={`meas_${i}`}>
                  <div className="measurement">{t(meas.label)}</div>
                  <Data>
                    {meas.value(measurement) ? `${parseFloat(meas && meas.value(measurement) / 10).toFixed(2)} cm` : 'N/A'}
                  </Data>
                </MeasurementList>
              </div>
            ))}
            <DottedLine />
            <MeasurementList>
              <div className="measurement">{t('avatarGender')}</div>
              <Data>{measurement.gender ? t(`${measurement.gender}`) : '-'} </Data>
            </MeasurementList>
            <MeasurementList>
              <div className="measurement">{t('avatarHeight')}</div>
              <Data>{measurement?.height ? `${measurement?.height / 10} cm` : ''}</Data>
            </MeasurementList>
          </MeasurementSize>
        </MeasurementContainer>
        {measurement['3DmodelPath'] ? <ThreeDModel modelUri={measurement['3DmodelPath']} /> : <NotValidPath>{t('invalidPath')}</NotValidPath>}
      </SubContainer>
      {measurement.measurementId && (
        <MeasurementId className="measurement">
          {copied && (
            <CopiedMeasurementId className="measurement">
              {t("Copied to clipboard!")}
            </CopiedMeasurementId>
          )}
          {t("your_measurement_id")}{" "}
          <span onClick={() => copyToClipboard(measurement.measurementId)} className="measurement">
            {measurement.measurementId}
          </span>
        </MeasurementId>
      )}
    </div>
  );
};

export default MeasurementResults;
