/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { Suspense, useRef, useState, useEffect } from 'react'
import { Canvas, useFrame, useThree } from '@react-three/fiber'
import { Html, Loader, OrbitControls } from '@react-three/drei'
import ThreeDMesh from './ThreeDMesh'
import styled from 'styled-components'

const Container = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  @media (max-width: 600px) {
    width: 90%;
  }
`;

function Rig({ children }) {

  const outer = useRef()
  const inner = useRef()
  return (
    <group position={[0, -0.7, 0]} ref={outer}>
      <group ref={inner}>{children}</group>
    </group>
  )
}

const CameraControls = () => {

  const {
    camera,
    gl: { domElement }
  } = useThree();

  const controls = useRef();
  useFrame(state => controls.current.update());
  return (
    <OrbitControls
      ref={controls}
      args={[camera, domElement]}
      // minDistance={500} // should look when open
      // maxDistance={700} // can be zoom to max level
      // maxAzimuthAngle={Math.PI / 2} // left right rotation
      // maxPolarAngle={Math.PI / 2} // up down rotation
      // minAzimuthAngle={-Math.PI / 2} // left right rotation
      // minPolarAngle={Math.PI / 2} // up down rotation 
    />
  );
};



function CanvasInfo(modelUri) {
  const [isMobile, setIsMobile] = useState(false);
  
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };
    window.addEventListener('resize', handleResize);
    
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <Canvas style={{ 
      width: isMobile ? '65%' : '75%',
      height: isMobile ? '300px' : '450px'
     }}
      camera={{ position: [0.00, 0.83, 2.01] }}
    >
      <CameraControls />
      <directionalLight position={[0, 10, 10]} intensity={2} />
      <pointLight position={[0, 100, 250]} />
      <Suspense
        fallback={
          <Html center>
            <Loader />
          </Html>
        }>
        <Rig>
          <ThreeDMesh meshUri={modelUri} />
        </Rig>
      </Suspense>
    </Canvas>
  )
}

export default function ThreeDModel({ modelUri }) {
 
  return (
    <Container>
      {modelUri ? CanvasInfo(modelUri) : <span>-</span>}
    </Container>
  )
}
