import { Fragment, useEffect, useState, useContext, useRef } from "react";
import styled, { keyframes } from "styled-components";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { setMeasurements, setRecommendations, } from "../../../actions/recommendationAction";
import {
  startMenu,
  setShowPhase,
  reuseMeasurementId,
  PHASE_MISSING_API_KEY,
} from "../../../actions/recommendationAction";
import Home from "../../home/Home";
import SizeChartResult from "../../SizeChartResult";
import { COLORS } from "../../../common/Colors";
import { getMeasurement } from "../../../api/recommendation-api";
import { FirebaseContext } from "../../../providers/FirebaseProviders"; 

const { BLUE } = COLORS;

const Container: any = styled.div`
  width: "100%";
  height: 100%;
  margin: 0 auto;
`;

const Heading = styled.h2`
    color: ${({ theme }) => theme.colors.text};
    text-transform: uppercase;
    font-size: 24px;
    margin: 17px 0px 0px 0px;
`;

const TryButton = styled.div`
  margin-top: 1em;
  margin: 10px 30px;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.text};
`;

const ResultContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 80vh;
  .MuiButton-outlinedPrimary {
    color: ${BLUE};
    border: 2px solid ${BLUE};
    font-size: 18px;
    font-weight: bold;
  }
  .btn-outlined {
      margin-top: 150px;
  }
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const LoadingScreen = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.text};
  height: 100vh;
  
  &::after {
    content: '';
    width: 40px;
    height: 40px;
    border: 4px solid ${({ theme }) => theme.colors.primary}; /* Choose your desired color */
    border-top: 4px solid transparent;
    border-radius: 50%;
    animation: ${spin} 1s linear infinite; /* Apply the spin animation */
  }
`;

function Measurement(props: any) {
  const { t } = useTranslation();
  const measurementId = useSelector((state: any) => state.userInfo);
  const { user } = useContext(FirebaseContext)
  const { sizeChart, recommendations } = useSelector(
    (state: any) => state.recommendationReducer
  );
  const location: any = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const query = new URLSearchParams(location.search);
  const paramsMeasurementId = query.get("measurementId");
  const url = new URL(window.location.href);
  const UPC: any = url.searchParams.get('UPC') || url.searchParams.get('upc');
  const productId: any = url.searchParams.get('productId');
  const brand: any = url.searchParams.get('brand');
  const garment: any = url.searchParams.get('garment');
  const flagRef = useRef(false);

  const [urlMeasurementId, setUrlMeasurementId] = useState('');

  const handleRoutesByParams = async () => {
    const apiKey = query.get("apikey");
    let id = null;

    if (paramsMeasurementId !== "null" && paramsMeasurementId !== 'undefined') {
      id = paramsMeasurementId;
    }
    else if (measurementId?.measurements.length > 0) {
      const tapeMeasurement = measurementId.measurements.filter((m: any) => m.tape);
      const digitalMeasurement = measurementId.measurements.filter((m: any) => !m.tape);
      if (measurementId.user.useTapeMeasurement) {
        if (tapeMeasurement.length > 0) {
          id = tapeMeasurement[0].measurement_id;
        } else {
          id = digitalMeasurement[0].measurement_id;
        }
      } else if(measurementId.user.useTapeMeasurement === false) {
        if (digitalMeasurement.length > 0) {
          id = digitalMeasurement[0].measurement_id;
        } else {
          id = tapeMeasurement[0].measurement_id;
        }
      }
    } else {
      id = null;
    }
    setUrlMeasurementId(id)
    const firstMeasurementId = id;

    if (!apiKey) {
      dispatch(setShowPhase(PHASE_MISSING_API_KEY));
    } else {
      if (paramsMeasurementId === "null" || paramsMeasurementId === 'undefined') {
        const newSearch: any = new URLSearchParams(location.search);
        let query1 = "?";
        for (const param of newSearch) {
          if (param[0] !== "measurementId") {
            query1 = query1 + "&" + param[0] + "=" + param[1];
          }
        }
        navigate(
          `${query1 + "&measurementId=" + firstMeasurementId}`
        );
        if (!UPC && !productId && !brand && !garment) {
          dispatch(reuseMeasurementId(firstMeasurementId));
        }
      } else if (paramsMeasurementId) {
        if (!UPC && !productId && !brand && !garment) {
          dispatch(reuseMeasurementId(paramsMeasurementId));
        }
      } else {
        dispatch(startMenu());
      }
    }
  };

  useEffect(() => {
    handleRoutesByParams(); 
    if (!measurementId.measurements.length && !!Object.keys(measurementId.user).length){
      flagRef.current = true;
    }
     // eslint-disable-next-line
  }, [measurementId.measurements?.length, measurementId.user]); 

  useEffect(() => {
    if (urlMeasurementId) {
      getMeasurement(urlMeasurementId)
        .then((me) => dispatch(setMeasurements(me.measurement)))
        .catch((err) => console.log(err));
    }
    // eslint-disable-next-line
  }, [urlMeasurementId])

  useEffect(() => {
    if (!user) {
      window.location.replace(`${process.env.REACT_APP_UI_RECOMMENDATION}${window.location.search}`);
    }else{
      const urlSearchParams = new URLSearchParams(window.location.search);
      if (urlMeasurementId) {
        if (urlSearchParams.has('measurementId')) {
          urlSearchParams.set('measurementId', urlMeasurementId);
        } else {
          urlSearchParams.append('measurementId', urlMeasurementId);
        }
        window.location.replace(`${process.env.REACT_APP_UI_RECOMMENDATION}?${urlSearchParams.toString()}`);
      }else if(paramsMeasurementId !== "null" && paramsMeasurementId !== 'undefined'){
        if(urlSearchParams.has('measurementId')) {
          urlSearchParams.set('measurementId', 'null');
        }else {
          urlSearchParams.append('measurementId', "null");
        }
        window.location.replace(`${process.env.REACT_APP_UI_RECOMMENDATION}?${urlSearchParams.toString()}`);
      }else if(flagRef.current){
        window.location.replace(`${process.env.REACT_APP_UI_RECOMMENDATION}?${urlSearchParams.toString()}`);
      }
    }
  }, [urlMeasurementId, user, paramsMeasurementId, flagRef, measurementId.user]);

  const tryAgain = () => {
    if (window.opener === null) {
      dispatch(setRecommendations(undefined));
      window.location.replace(`${process.env.REACT_APP_UI_RECOMMENDATION}${window.location.search}`);
    } else {
      window.close();
    }
  }
  return (
    <Container>
      {!sizeChart && (!!UPC || !!productId || (!!brand && !!garment)) ? <LoadingScreen /> : (((recommendations || typeof recommendations === 'boolean') && (UPC || productId || (brand && garment))) ?
          <ResultContainer> {(!recommendations || recommendations.length === 0) ?
            <div>
              <Heading>{t('no_recommendation')}</Heading>
              <TryButton
                onClick={tryAgain}
              >
                {t("Try Again")}
              </TryButton>
            </div>
            :
            <SizeChartResult />}
          </ResultContainer>
          :
          <Fragment>
              {!recommendations && <Home />}
          </Fragment>)}
    </Container>
  );
}

export default Measurement;
