import { Dispatch } from "redux";
import {
  createSilhouette,
  getMeasurements,
  getMeasurement,
  getSizeChartApi,
  get3DModel,
  tapeMeasurementApi,
  updateTapeMeasureApi,
  getProductByUpcApi,
  getProductByIdApi,
} from "../api/recommendation-api";

export const SET_PHASE = "SET_PHASE";
export const SET_GENDER = "SET_GENDER";
export const SET_HEIGHT = "SET_HEIGHT";
export const SET_WEIGHT = "SET_WEIGHT";
export const PHASE_FRONT_VIEW_INSTRUCTIONS = "PHASE_FRONT_VIEW_INSTRUCTIONS";
export const PHASE_FRONT_VIEW_CAMERA = "PHASE_FRONT_VIEW_CAMERA";
export const SET_CAPTURE_IMAGE = "SET_CAPTURE_IMAGE";
export const PHASE_SIDE_VIEW_CAMERA = "PHASE_SIDE_VIEW_CAMERA";
export const SET_FRONT_PICTURE = "SET_FRONT_PICTURE";
export const PHASE_PROCESS_FRONT_PICTURE = "PHASE_PROCESS_FRONT_PICTURE";
export const PHASE_FRONT_VIEW_REVIEW = "PHASE_FRONT_VIEW_REVIEW";
export const PHASE_MISSING_API_KEY = "PHASE_MISSING_API_KEY";
export const PHASE_MEASURING = "PHASE_MEASURING";
export const PHASE_MEASUREMENTS = "PHASE_MEASUREMENTS";
export const PHASE_SIDE_VIEW_REVIEW = "PHASE_SIDE_VIEW_REVIEW";
export const SET_SIDE_PICTURE = "SET_SIDE_PICTURE";
export const SET_CAMERA_TIMER = "SET_CAMERA_TIMER";
export const TAKE_PICTURE = "TAKE_PICTURE";
export const PHASE_BASIC = "PHASE_BASIC";
export const REQUEST_PICTURE = "REQUEST_PICTURE";
export const CLEAR_MEASUREMENTS_DATA = "CLEAR_MEASUREMENTS_DATA";
export const MEASUREMENT_FAILED = "MEASUREMENT_FAILED";
export const SET_MEASUREMENTS = "SET_MEASUREMENTS";
export const SET_RECOMMENDATIONS = "SET_RECOMMENDATIONS";
export const PHASE_START = "PHASE_START";
export const PHASE_PROCESS_SIDE_PICTURE = "PHASE_PROCESS_SIDE_PICTURE";
export const PHASE_RETURNING = "PHASE_RETURNING";
export const PHASE_REUSE_MEASUREMENT = "PHASE_REUSE_MEASUREMENT";
export const PHASE_SIDE_VIEW_INSTRUCTIONS = "PHASE_SIDE_VIEW_INSTRUCTIONS";
export const CLEAR_BOTH_PICTURES_DATA = "CLEAR_BOTH_PICTURES_DATA";
export const SET_MEASUREMENT_FAILED_MESSAGE = "SET_MEASUREMENT_FAILED_MESSAGE";
export const SET_SIZE_CHART = "SET_SIZE_CHART";
export const SET_MEASUREMENT_USING_PRODUCTID = "SET_MEASUREMENT_USING_PRODUCTID";
export const SET_TAPE_MEASUREMENT = "SET_TAPE_MEASUREMENT";
export const SET_BACK_PHASE = "SET_BACK_PHASE";
export const SIZEY_PROFILE = "SIZEY_PROFILE";
export const MY_MEASUREMENT = "MY_MEASUREMENT";
export const SIZEY_SHOP = "SIZEY_SHOP";
export const SET_HOME_CARD = "SET_HOME_CARD";
export const SET_SLIDER_VALUE = "SET_SLIDER_VALUE";

export const setPicture = (type: string, payload: any) => ({ type, payload });

export const setShowPhase = (phase: string) => ({
  type: SET_PHASE,
  phase: phase,
});

export const setBackPhase = (phase: string) => ({
  type: SET_BACK_PHASE,
  backPhase: phase,
})

export const setClearMeasurement = () => ({
  type: CLEAR_MEASUREMENTS_DATA,
});

export const setClearBothPictures = () => ({
  type: CLEAR_BOTH_PICTURES_DATA,
});

export const setUserGender = (gender: string) => ({
  type: SET_GENDER,
  payload: gender,
});

export const setUserHeight = (height: any) => ({
  type: SET_HEIGHT,
  payload: height,
});

export const setUserWeight = (weight: any) => ({
  type: SET_WEIGHT,
  payload: weight,
});

export const setHomeCard = (phase: string) => ({
  type: SET_HOME_CARD,
  payload: phase,
})

const setMeasurementFailedMsg = (errorMsg: string) => ({
  type: SET_MEASUREMENT_FAILED_MESSAGE,
  payload: errorMsg,
})


const setMeasurementFailed = (measurement = false) => ({
  type: MEASUREMENT_FAILED,
  payload: measurement,
});

export const setMeasurements = (measurements: any) => ({
  type: SET_MEASUREMENTS,
  payload: measurements,
});

export const setRecommendations = (recommendations: any) => ({
  type: SET_RECOMMENDATIONS,
  payload: recommendations,
});

const setCameraTimer = (timer: any) => ({
  type: SET_CAMERA_TIMER,
  payload: timer,
});

const setRequestPictures = () => ({
  type: REQUEST_PICTURE,
});

const setTakePicture = () => ({
  type: TAKE_PICTURE,
  payload: false,
});


export const setSizeChart = (sizeChart: any) => ({
  type: SET_SIZE_CHART,
  payload: sizeChart,
})

const setTapeMeasurement = (tapeMeasurement: any) => ({
  type: SET_TAPE_MEASUREMENT,
  payload: tapeMeasurement
})

export const setMeasurementUsingProductId = (measurement: boolean) => ({
  type: SET_MEASUREMENT_USING_PRODUCTID,
  payload: measurement
})

export const setSliderValue = (value: any) => ({
  type: SET_SLIDER_VALUE,
  payload: value
})

export const setRequestPicture = (takePicture: any) => (dispatch: Dispatch) => {
  if (takePicture) {
    dispatch(setRequestPictures());
    for (let x = takePicture.payload.timer; x > 0; x = x - 1) {
      dispatch(setCameraTimer(x));
    }
    dispatch(setTakePicture());
    dispatch(
      setSidePicture(
        takePicture.payload.camera.getScreenshot(takePicture.payload.dimensions)
      )
    );
  }
};

export const setHumanGenderAndHeight = (data: any) => (dispatch: Dispatch) => {
  if (data) {
    const { gender, height, weight } = data.values;
    dispatch(setUserGender(gender));
    dispatch(setUserHeight(+height * 10));
    dispatch(setUserWeight(weight));
    dispatch(setShowPhase(PHASE_FRONT_VIEW_INSTRUCTIONS));
  }
};

export const setSidePicture = (data: any) => ({
  type: SET_SIDE_PICTURE,
  payload: data,
});


const getStateValue = (state: any, key: string) => {
  return state.recommendationReducer[key] || "";
};

const getBasicDetails = (state: any) => {
  const frontPicture = getStateValue(state, "frontPicture");
  const sidePicture = getStateValue(state, "sidePicture");
  const height = getStateValue(state, "height");
  const weight = getStateValue(state, "weight");
  const gender = getStateValue(state, "gender");
  const phase = getStateValue(state, "phase");
  const isFront = phase === PHASE_PROCESS_FRONT_PICTURE;
  return { frontPicture, sidePicture, height, weight, gender, isFront };
};


export const submitMeasurements =
  () => async (dispatch: Dispatch, state: any) => {
    dispatch(setShowPhase(PHASE_MEASURING));
    dispatch(setClearMeasurement());
    const { frontPicture, sidePicture, height, weight, gender } = getBasicDetails(
      state()
    );
    const url = new URL(window.location.href);
    // const UPC = url.searchParams.get("UPC") || url.searchParams.get('upc');
    // const productId = url.searchParams.get("productId");
    // const chartId = url.searchParams.get("chartId");
    // const brand = url.searchParams.get("brand");
    // const garment = url.searchParams.get("garment");
    // const extra = url.searchParams.get("extra");
    // const sizeType = url.searchParams.get("sizeType");
    // const measureOnly = url.searchParams.get("measureOnly");
    url.searchParams.get("gender");
    try {
      const measurements = await getMeasurements({
        frontPicture,
        sidePicture,
        height,
        weight,
        gender
      });
      dispatch(setMeasurements(measurements));

      // if (!measureOnly) {
      //   const sizeChart = UPC
      //     ? { UPC } : productId ? { productId } 
      //     : chartId
      //       ? { chart: chartId }
      //       : { brand, gender, garment, extra };
      //   const recommendations = await getRecommendations({
      //     measurements,
      //     sizeChart,
      //     sizeType,
      //   });
      //   dispatch(setRecommendations(recommendations));
      // }
    } catch (error) {
      dispatch(setMeasurementFailed(true));
    }
    dispatch(setShowPhase(PHASE_MEASUREMENTS));
  };
const basicInformation = (dispatch: Dispatch) => {
  dispatch(setShowPhase(PHASE_BASIC));
};

export const captureImage = (data: any) => (dispatch: Dispatch, state: any) => {
  const { image, orientation, fileExtension } = data;
  const isFront = orientation === "front";
  const { height, gender } = getBasicDetails(state());

  if (fileExtension) {
    createSilhouette(image, height, gender, isFront)
      .then((data) => {
        dispatch(setPicture(isFront ? SET_FRONT_PICTURE : SET_SIDE_PICTURE, data));
      })
      .catch((error) => {
        dispatch(setPicture(isFront ? SET_FRONT_PICTURE : SET_SIDE_PICTURE, error));
      });
    dispatch(
      setShowPhase(
        !isFront ? PHASE_PROCESS_FRONT_PICTURE : PHASE_SIDE_VIEW_CAMERA
      )
    );
  }
};

const startMenuWithGenderAndHeight = (
  gender: string,
  height: string,
  weight: string,
  dispatch: Dispatch
) => {
  dispatch(setUserGender(gender));
  dispatch(setUserHeight(+height * 10));
  dispatch(setUserWeight(weight));

  dispatch(setShowPhase(PHASE_FRONT_VIEW_INSTRUCTIONS));
};

export const reuseMeasurements =
  (measurements: any) => async (dispatch: Dispatch) => {
    dispatch(setShowPhase(PHASE_MEASURING));

    dispatch(setClearMeasurement());

    // const url = new URL(window.location.href);
    // const UPC = url.searchParams.get("UPC") || url.searchParams.get('upc');
    // const productId = url.searchParams.get("productId");
    // const chartId = url.searchParams.get("chartId");
    // const brand = url.searchParams.get("brand");
    // const gender = url.searchParams.get("gender");
    // const garment = url.searchParams.get("garment");
    // const sizeType = url.searchParams.get("sizeType");
    // const extra = url.searchParams.get("extra");
    // const measureOnly = url.searchParams.get("measureOnly");

    // const sizeChart = UPC
      // ? { UPC } : productId ? { productId } 
      // : chartId
      //   ? { chart: chartId }
      //   : { brand, gender, garment, extra };

    // try {
    //   if (!measureOnly) {
        // const recommendations = await getRecommendations({
        //   measurements,
        //   sizeChart,
        //   brand,
        //   garment,
        //   gender,
        //   extra,
        //   sizeType,
        // });
        // dispatch(setRecommendations(recommendations));
    //   }
    // } catch (e) {
    //   console.error(e)
    // }
    dispatch(setMeasurements(measurements));
    dispatch(setShowPhase(PHASE_MEASUREMENTS));
  };

export const reuseMeasurementId =
  (measurementId: any) => async (dispatch: any) => {
    try {
      if (measurementId) {
        const measurements = await getMeasurement(measurementId);
        if (measurements.status === 401) {
          dispatch(setMeasurementFailedMsg("Authorization failed!"));
          dispatch(setMeasurementFailed(true));
          dispatch(setShowPhase(PHASE_MEASUREMENTS));
        }
        if (measurements.success === false) {
          dispatch(setMeasurementFailedMsg("size charts service is not available."));
          dispatch(setMeasurementFailed(true));
          return dispatch(setShowPhase(PHASE_MEASUREMENTS));
        }
        if (measurements.measurement) {
          const model = await get3DModel(measurementId)
          measurements['3DmodelPath'] = model;
          dispatch(reuseMeasurements({ ...measurements.measurement, ...measurements }));
        }
      }
    } catch (e) {
      dispatch(setMeasurementFailed(true));
      dispatch(setShowPhase(PHASE_MEASUREMENTS));
    }
  };

export const startMenu = () => (dispatch: Dispatch) => {
  const url = new URL(window.location.href);
  const height = url.searchParams.get("height");
  const weight = url.searchParams.get("weight");
  const gender = url.searchParams.get("humanGender");
  if (gender && height && weight) {
    startMenuWithGenderAndHeight(gender, height, weight, dispatch);
    dispatch(setBackPhase('/'))
  } else {
    dispatch(setShowPhase(PHASE_START));
    basicInformation(dispatch);
    dispatch(setBackPhase(PHASE_START))
  }
};

export const getSizeChart = (UPC: string,productId:string) => async (dispatch: Dispatch) => {
  try {
      
      let product;
      if(UPC) {
        product = await getProductByUpcApi(UPC);
      } else if(productId) {
        product = await getProductByIdApi(productId);

      }
      if (!product.sizeChart) {
        return dispatch(setSizeChart({ isNotSizeChart: true, message: `size charts service is not available.` }));
      }
      if (product.sizeChart) {
        const sizeChart = await getSizeChartApi(product.sizeChart.id);
        if (Object.keys(sizeChart).length > 0) {
          dispatch(setSizeChart({ ...sizeChart, ...product }));
        } else {
          dispatch(setSizeChart({ isNotSizeChart: true, message: `No size chart exist for this UPC: ${UPC}` }));
        }
      } else {
        dispatch(setSizeChart({ isNotSizeChart: true, message: `No product found` }));
      }
  } catch (e) {
    dispatch(setShowPhase(PHASE_START));
  }
}

export const tapeMeasurement = (measurement: any) => async (dispatch: Dispatch) => {
  try {
    const tapeMeasurement = await tapeMeasurementApi(measurement);
    return tapeMeasurement;
  } catch (err) {
    console.log("error", err)
  }
}

export const updateTapeMeasure = (measurementId: string, measurement: any) => async (dispatch: Dispatch) => {
  try {
    const tapeMeasurement = await updateTapeMeasureApi(measurementId, measurement);
    return tapeMeasurement;
  } catch (err) {
    console.log("error", err)
  }
}

export const getTapMeasurement = (measurementId: string) => async (dispatch: Dispatch) => {
  try {
    const tapeMeasurements = await getMeasurement(measurementId);
    dispatch(setTapeMeasurement(tapeMeasurements))
  } catch (err) {
    dispatch(setTapeMeasurement({}))
  }
}
